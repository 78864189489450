import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', 
    name: 'Home',
    component: () => import(/* webpackChunkName: "components" */`@/views/HomeView.vue`)
  },
  { 
    path: '/lol',
    name: 'gamedata',
    component: () => import(/* webpackChunkName: "lol" */`@/views/Gamedata/GamedataView.vue`),
    children: [
      {
        path: '',
        name: 'gamedata',
        component: () => import(/* webpackChunkName: "lol" */`@/views/Gamedata/MenuView.vue`)
      },
      {
        path: 'champions',
        name: 'lol-champions',
        component: () => import(/* webpackChunkName: "lol" */`@/views/Gamedata/ChampionsView.vue`)
      },
      {
        path: 'champion/:champion',
        name: 'champion',
        props: true,
        component: () => import(/* webpackChunkName: "lol" */`@/views/Gamedata/ChampionView.vue`)
      },
      {
        path: 'objets',
        name: 'items',
        component: () => import(/* webpackChunkName: "lol" */`@/views/Gamedata/ItemsView.vue`)
      }
    ]
  },
  { 
    path: '/informations',
    name: 'informations',
    component: () => import(/* webpackChunkName: "informations" */`@/views/Informations/InformationsView.vue`),
    children: [
      {
        path: '',
        name: 'informations',
        component: () => import(/* webpackChunkName: "informations" */`@/views/Informations/MenuView.vue`)
      },
      {
        path: 'mentions-legales',
        name: 'legalenotice',
        component: () => import(/* webpackChunkName: "informations" */`@/views/Informations/LegaleNoticeView.vue`)
      },
      {
        path: 'politique-confidentialite',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "informations" */`@/views/Informations/PrivacyView.vue`)
      },
      {
        path: 'politique-cookies',
        name: 'cookies',
        component: () => import(/* webpackChunkName: "informations" */`@/views/Informations/CookiesView.vue`)
      }
    ]
  },
  {
    path: '/lft-imagemaker',
    name: 'lft',
    component: () => import(/* webpackChunkName: "LFT" */`@/views/LFT/LftView.vue`),
    children: [
      {
        path: '',
        name: 'lft',
        component: () => import(/* webpackChunkName: "LFT" */`@/views/LFT/GeneratorView.vue`)
      }
    ]
  },
  {
    path: '/communities',
    name: 'community',
    component: () => import(/* webpackChunkName: "Community" */`@/views/Community/CommunityView.vue`),
    children: [
      {
        path: '',
        name: 'community',
        component: () => import(/* webpackChunkName: "Community" */`@/views/Community/MenuView.vue`)
      }
    ]
  },
  {
    path: '/tierlists',
    name: 'tierlist',
    component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/TierlistView.vue`),
    children: [
      {
        path: '',
        name: 'tierlist',
        component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/MenuView.vue`)
      },
      {
        path: 'champions',
        name: 'tierlist-champions',
        component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/ChampionsView.vue`)
      },
      {
        path: 'items',
        name: 'tierlist-items',
        component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/ItemsView.vue`)
      },
      {
        path: 'esport',
        name: 'tierlist-esport',
        component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/EsportView.vue`),
        children: [
          {
            path: '',
            name: 'tierlist-esport-menu',
            component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/Esport/MenuView.vue`)
          },
          {
            path: 'ligues',
            name: 'tierlist-esport-ligues',
            component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/Esport/LiguesView.vue`)
          },
          {
            path: 'teams',
            name: 'tierlist-esport-teams',
            component: () => import(/* webpackChunkName: "Tierlist" */`@/views/Tierlist/Esport/TeamsView.vue`)
          },
        ]
      }
    ]
  },
  { 
    path: '/:pathMatch(.*)', 
    redirect: '/404' 
  },
  {
    path: '/404',
    name: 'notfound',
    component: () => import(/* webpackChunkName: "components" */`@/views/NotFoundView.vue`)
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router