<template>

    <div id="settings">

        <div class="settings--container">

            <header>
            
                <h2>Settings</h2>

                <i @click="$emit('settings')" class="fa-solid fa-xmark"></i>

            </header>

            <div class="settings--content">

                <ul>

                    <li @click="changeTab('hextech')" :class="isTabActive('hextech')">

                        <span>Hextech Tools</span>

                    </li>

                    <hr>

                    <li @click="changeTab('gamedata')" :class="isTabActive('gamedata')">

                        <span>Game Data</span>

                    </li>

                </ul>

                <section v-if="activeTab == 'hextech'">
                    
                    <h3>Hextech Tools</h3>

                    <div>
                        <h4>Gestion des cookies</h4>
                        <select v-model="unsavedConfig.cookies.consent">
                            <option selected :value="unsavedConfig.cookies.consent"></option>
                            <option :value="!unsavedConfig.cookies.consent"></option>
                        </select>
                        <router-link @click="$emit('settings')" to="/informations/politique-cookies">Politique en matière de cookies</router-link>
                    </div>

                </section>

                <section v-if="activeTab == 'gamedata'">
                    
                    <h3>Données de jeu</h3>

                    <div>
                        <h4>Version</h4>
                        <select v-model="unsavedConfig.patch">
                            <option selected :value="unsavedConfig.patch"> {{ returnPatchLabel(unsavedConfig.patch) }} </option>
                            <option v-for="patch in getPatchesList()" :key="patch" :value="patch">{{ returnPatchLabel(patch) }}</option>
                        </select>
                    </div>

                    <div>
                        <h4>Language</h4>
                        <select v-model="unsavedConfig.locale">
                            <option selected :value="unsavedConfig.locale"> {{ `${locales[unsavedConfig.locale].label} [${unsavedConfig.locale}]` }} </option>
                            <option v-for="locale in getLocalesList()" :key="locale.value" :value="locale.value">{{ `${locale.label} [${locale.value}]` }}</option>
                        </select>
                    </div>

                </section>

            </div>

            <footer>

                <button @click="resetConfig()" :disabled="isDifferent" class="reset">
                    <i class="fa-solid fa-clock-rotate-left"></i>
                    <span>Réinitialiser</span>
                </button>

                <button @click="saveConfig()" :disabled="isDifferent">
                    <i class="fa-solid fa-floppy-disk"></i>
                    <span>Sauvegarder</span>
                </button>

            </footer>

        </div>

    </div>
  
</template>

<script>

    export default {
        name: "SettingsComponent",
        props: ['translations', 'config', 'patches', 'locales'],
        emits: ['settings', 'saveConfig'],
        data() {
            return {
                unsavedConfig: this.resetConfig(),
                isDifferent: true,
                activeTab: 'hextech'
            }
        },
        created() {
            this.resetConfig()
        },
        methods: {
            isTabActive (tab) {
                if (tab === this.activeTab) {
                    return 'active'
                } else {
                    return ''
                }
            },
            changeTab (tab) {
                this.activeTab = tab
            },
            getPatchesList() {
                var properties = []
                for (const property in this.patches) {
                    if (this.patches[property] != this.unsavedConfig.patch) {
                        properties.push(this.patches[property])
                    }
                }
                return properties
            },
            getLocalesList () {
                var properties = []
                for (const property in this.locales) {
                    if (property != this.unsavedConfig.locale) {
                        properties.push(this.locales[property])
                    }
                }
                return properties
            },
            resetConfig() {
                this.unsavedConfig = {
                    locale: this.config.locale,
                    patch: this.config.patch,
                    cookies: {
                        consent: this.config.cookies.consent,
                        answeredOnce: this.config.cookies.answeredOnce
                    }
                }
            },
            saveConfig() {
                this.$emit('saveConfig', this.unsavedConfig)
            },
            returnPatchLabel (patch) {
                const index = this.patches.indexOf(patch)
                if (!index && patch == this.unsavedConfig.patch) {
                    return `${patch} (+ récent) (actif)`
                } else if (!index && patch != this.unsavedConfig.patch) {
                    return `${patch} (+ récent)`
                } else if (index && patch == this.unsavedConfig.patch) {
                    return `${patch} (actif)`
                } else {
                    return patch
                }
            },
        },
        watch: {
            unsavedConfig: {
                handler: function () {

                    const unsavedConfig = JSON.stringify(this.unsavedConfig)
                    const config = JSON.stringify(this.config)

                    this.isDifferent = unsavedConfig === config

                    if(this.unsavedConfig.cookies.consent != this.config.cookies.consent) {
                        this.unsavedConfig.cookies.answeredOnce = true
                    }
      
                },
                deep: true
            }
        }
    }

</script>

<style scoped>

    #settings {
        position: fixed;
        z-index: 99;
        max-width: var(--width-vw);
        width: 100%;
        height: 100vh;
        inset: 0px;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: brightness(0.75) blur(4px);
        -webkit-backdrop-filter: brightness(0.75) blur(4px);
    }

    .settings--container {
        max-width: var(--width-max);
        width: 100%;
        inset: 50% auto auto 50%;;
        padding: 1rem;
        background-color: var(--color-black);
        border-radius: 4px;
        border: 1px solid var(--color-gray);
        color: var(--color-light);
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-gray);
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }

    header i {
        font-size: 1.5rem;
        cursor: pointer;
        transition: 0.25s;
        opacity: 0.5;
    }

    header i:hover {
        opacity: 1;
    }

    ul {
        display: flex;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--color-gray);
        overflow-x: auto;
    }

    li {
        padding: 1rem 0.5rem;
        cursor: pointer;
        transition: 0.25s;
        text-transform: uppercase;
        font-size: 12px;
    }

    li:hover {
        backdrop-filter: brightness(3);
        text-decoration: underline var(--color-light);
    }

    tr {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .active {
        cursor: default;
        background-color: var(--color-yellow);
        color: var(--color-black);
    }

    .active:hover {
        text-decoration: none;
    }

    hr {
        border-right: 1px solid var(--color-gray);
    }

    section {
        padding: 1rem;
        text-align: left;
        max-height: 30vh;
        min-height: 30vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }

    h3 {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid var(--color-gray);
    }

    section > div {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }

    select {
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'HT';
    }

    footer {
        margin-top: 0.5rem;
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--color-gray);
    }

    button {
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-weight: bold;
        text-transform: uppercase;
        font-family: 'HT';
    }

    button:enabled {
        background-color: var(--color-yellow);
    }

    button:enabled:hover {
        box-shadow: 0px 0px 10px 0px var(--color-yellow);
        -webkit-box-shadow: 0px 0px 10px 0px var(--color-yellow);
        -moz-box-shadow: 0px 0px 10px 0px var(--color-yellow);
    }

    button:enabled:hover > span {
        text-decoration: underline var(--color-black);
    }

    button:disabled {
        cursor: not-allowed;
    }

    .reset:enabled {
        background-color: var(--color-red);
    }

    .reset:enabled:hover {
        box-shadow: 0px 0px 10px 0px var(--color-red);
        -webkit-box-shadow: 0px 0px 10px 0px var(--color-red);
        -moz-box-shadow: 0px 0px 10px 0px var(--color-red);
    }

    a {
        color: var(--color-yellow);
        transition: 0.25s;
    }

    a:hover {
        text-decoration: underline var(--color-yellow);
    }

</style>