<template>

    <header>

        <div class="header--container">

            <router-link to="/">
                <img src="@/assets/images/logos/logo.png" alt="Hextech Tools Logo">
                <h1>HEXTECH<br>TOOLS</h1>
            </router-link>

        </div>

    </header>

    <div id="sticky--header" class="header--controls">

        <div class="controls">

            <div class="controls--settings">
                <i @click="$emit('settings')" class="fa-solid fa-gear"></i>
            </div>

            <nav>

                <div class="nav--controls">
                    <i v-if="menu == false" @click="manageMenu(true)" class="fa-solid fa-bars"></i>
                    <i v-else-if="menu == true" @click="manageMenu(false)" class="fa-solid fa-xmark"></i>
                </div>

                <MenuComponent v-show="menu" :menu="menu" @manageMenu="manageMenu" :config="config" :translations="translations" />

            </nav>
                
        </div>

    </div>
  
</template>

<script>

    import MenuComponent from '../menu/MenuComponent.vue'

    export default {
        name: 'HeaderComponent',
        props: ['translations', 'config', 'menu'],
        emits: ['settings', 'manageMenu'],
        components: {
            MenuComponent
        },
        methods: {
            manageMenu (state) {
                this.$emit('manageMenu', state)
            }
        }
    }


</script>

<style scoped>

    header {
        padding: 0 1rem;
        background-color: var(--color-black);
        z-index: 10;
    }

    .header--controls {
        position: sticky;
        top: 0;
        height: 49px;
        background-color: var(--color-yellow);
        padding: 0 1rem;
        z-index: 2;
        box-shadow: 1px 4px 5px -1px rgba(0,0,0,0.75);
        -webkit-box-shadow: 1px 4px 5px -1px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 4px 5px -1px rgba(0,0,0,0.75);
    }

    .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 100%;
    }

    nav {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .nav--controls {
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: var(--color-yellow);
    }

    .controls--settings {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        z-index: 5;
    }

    .header--container {
        padding: 1rem 0;
        display: flex;
        align-items: center;
    }

    .header--container > a {
        display: flex;
        align-items: center;
    }

    .controls,
    .header--container {
        max-width: var(--width-max);
        margin: 0 auto;
    }

    i {
        cursor: pointer;
        font-size: 26px;
        color: var(--color-black);
        opacity: 0.75;
        transition: 0.25s;
    }

    i:hover {
        opacity: 1;
    }

    select {
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 26px;
        background-color: transparent;
        opacity: 0.75;
        transition: 0.25s;
    }

    select:hover {
        opacity: 1;
        text-decoration: underline var(--color-black);
    }

    h1 {
        text-align: left;
        font-size: 26px;
        color: var(--color-yellow);
    }

    img {
        width: 56px;
        height: 56px;
    }

</style>