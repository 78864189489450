import { initializeApp } from "firebase/app";

// Firebase required imports
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

// Your firebase config (copy-paste it from your firebase project)
const firebaseConfig = {
  apiKey: "AIzaSyDKO8XhWnP3DXeWGuDdgwMfF2wS2-uD-y4",
  authDomain: "newhextechtools.firebaseapp.com",
  projectId: "newhextechtools",
  storageBucket: "newhextechtools.appspot.com",
  messagingSenderId: "680404940049",
  appId: "1:680404940049:web:62fcb64163f2ee9212c8b0",
  measurementId: "G-5Q61PDSB9V"
};

// Firebase app initialization
export const app = initializeApp(firebaseConfig)

// Firebase required exports
export const firestore = getFirestore(app)
export const storage = getStorage(app)

