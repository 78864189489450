<template>

  <SettingsComponent v-if="controller && settings" @saveConfig="saveConfig" @settings="settings = !settings" :translations="translations" :config="config" :patches="patches" :locales="locales" />

  <HeaderComponent v-if="controller" @settings="settings = !settings" @manageMenu="manageMenu" :translations="translations" :config="config" :menu="menu" />
  
  <CookiesComponent v-if="controller && (!config.cookies.answeredOnce && !config.cookies.consent)" :translations="translations" :config="config" @saveConfig="saveConfig" />
 
  <div id="data-controller" v-if="controller">

    <main>

      <router-view :translations="translations" :config="config" />

    </main>


  </div>

  <FooterComponent v-if="controller" :translations="translations" :config="config" />

</template>

<script>

  import axios from 'axios'

  import { app, firestore } from './firebase'

  import { getAnalytics } from 'firebase/analytics'
  import { doc, getDoc, query, collection, getDocs } from 'firebase/firestore'

  import HeaderComponent from './components/header/HeaderComponent.vue'
  import SettingsComponent from './components/settings/SettingsComponent.vue'
  import FooterComponent from './components/footer/FooterComponent.vue'
  import CookiesComponent from './components/cookies/CookiesComponent.vue'


  export default {
    name: 'HextechTools',
    components: {
      HeaderComponent, SettingsComponent, FooterComponent, CookiesComponent
    },
    data() {
      return {
        config: JSON.parse(localStorage.getItem('hextech-config')) || null,
        patches: [],
        translations: [],
        locales: [],
        controller: false,
        settings: false,
        menu: false
      }
    },
    watch: {
      config: {
        handler: function (newConfig, oldConfig) {
          localStorage['hextech-config'] = JSON.stringify(newConfig)
          
          if (newConfig.cookies.consent) {
            this.initAnalytics()
          }

          if (oldConfig) {
            if (!newConfig.cookies.consent && oldConfig.cookies.consent) {
              document.cookie = '_ga=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC'; 
              document.cookie = '_ga_98N4W965T7=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC';
            }
          }
          
        },
        deep: true
      },
      settings(newSetting) {
        this.manageBody(newSetting)
      }
    },
    methods: {
      initAnalytics () {
        if (this.config.cookies.consent) {
          getAnalytics(app)
        }
      },  
      initLocalStorage () {
        if(!localStorage['hextech-config']) {
          localStorage['hextech-config'] = 
            JSON.stringify({
              locale: 'en_US',
              patch: this.patches[0],
              cookies: {
                consent: false,
                answeredOnce: false
              }
            })
        }
        this.refreshConfig()
      },
      async getPatches () {
        const data = await axios.get(`https://ddragon.leagueoflegends.com/api/versions.json`)
        this.patches = data.data.slice(0, data.data.indexOf('3.6.14') + 1)
      },
      async getTranslations () {
        const q = query(collection(firestore, "translations"))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          const id = doc.id
          const data = doc.data()
          this.translations[id] = data
        })
      },
      async getLocales () {
        const ref = doc(firestore, "config", "locales")
        const docSnap = await getDoc(ref)
        var locales = docSnap.data()
        for (var locale in locales) {
          this.locales = {
            ...this.locales,
            [locale]: {
              ...locales[locale]
            }
          }
        }
      },
      saveConfig (newConfig) {
        localStorage['hextech-config'] = JSON.stringify(newConfig)
        this.settings = false
        this.refreshConfig()
      },
      refreshConfig () {
        var config = JSON.parse(localStorage.getItem('hextech-config'))
        this.config = {
          locale: config.locale,
          patch: config.patch,
          cookies: {
            consent: config.cookies.consent,
            answeredOnce: config.cookies.answeredOnce
          }
        }
      },
      manageBody (state) {
        if(state) {
          var scrollX = window.scrollY;
          document.body.style.position = 'fixed';
          document.body.style.top = `-${scrollX}px`;
        } else {
          const scrollY = document.body.style.top
          document.body.style.position = ''
          document.body.style.top = ''
          window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
      },
      manageMenu (state) {
        this.menu = state
      }
    },
    async created() {
      await this.getPatches()
      await this.initLocalStorage()
      await this.getTranslations()
      await this.getLocales()
      await this.initAnalytics()
      this.controller = true
    }
  }

</script>

<style>

  /* CSS VARIABLES  */

  :root {
    /* COLORS VARIABLES */
    --color-black: #010a13;
    --color-blackish: #06141d;
    --color-grayish: #232a2e;
    --color-gray: #8b8b8b;
    --color-light: #FFFFFF;
    --color-gold: #e3ba3d;
    --color-yellow: #d9b28a;
    --color-yellowish: #f0e6d2;
    --color-bluer: #02282d;
    --color-blue: #0acae5;
    --color-red: #ff2345;
    --color-green: #16e61e;
    /* SIZES VARIABLES*/
    --width-vw: 100vw;
    --width-max: 1200px;
  }

  /* INITIAL CSS RULES */

  * {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'HT';
  }

  /* CUSTOM FONTS */

  @font-face {
    font-family: "HT";
    src: url('./assets/fonts/HT.ttf')
  }

  html,
  body, 
  #hextech-tools {
    min-height: 100vh;
    width: 100%;
    background-color: var(--color-black);
  }

  #hextech-tools {
    font-family: 'HT';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  #data-controller {
    padding: 1rem;
    min-height: calc(100vh - 261px);
    overflow: hidden;
  }

  main {
    max-width: var(--width-max);
    min-height: calc(100vh - 261px);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: var(--color-light);
  }

  /* GLOBAL RULES */

  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h2, h3, h4 {
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-after-enter-active {
    transition: opacity 0.5s 0.5s ease;
  }
  
  .fade-after-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-after-enter-from,
  .fade-after-leave-to {
    opacity: 0;
  }



</style>
