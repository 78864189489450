<template>

    <Transition name="slide" appear>
    
    <div id="menu">

        <nav>

            <h2>Menu</h2>

            <ul> 

                <li>

                    <router-link to="/">Hextech Tools</router-link>

                </li>

                <li>

                    <router-link to="/lol">League of Legends Data</router-link>

                    <div class="links--children">
                        
                        <router-link to="/lol/champions">Champions</router-link>

                        <router-link v-if="$route.name === 'champion'" :to="`/gamedata/champion/${getChampion}`">{{ getChampion }}</router-link>

                        <router-link to="/lol/objets">Items</router-link>

                    </div>

                </li>

                <li>

                    <router-link to="/lft-imagemaker">LFT-image maker</router-link>

                </li>

                <li>

                    <router-link to="/communities">communities</router-link>

                </li>

            </ul>

        </nav>

    </div>

    </Transition>
  
</template>

<script>

    export default {
        name: 'MenuComponent',
        props: ['translations', 'config', 'champion', 'menu'],
        emits: ['manageMenu'],
        computed: {
            getChampion () {
                const path = this.$route.path
                const champion = path.substr(path.lastIndexOf('/') + 1, path.length)
                return champion
            }
        },
        watch: {
            '$route' () {
               this.$emit('manageMenu', false)
            }
        }
    }

</script>

<style scoped>

    #menu {
        position: absolute;
        top: 64px;
        z-index: 3;
        background-color: var(--color-black);
        max-width: var(--width-vw);
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        border: 1px solid var(--color-light);
        border-radius: 4px;
    }

    nav {
        max-width: var(--width-max);
        width: 100%;
        color: var(--color-light);
        text-align: left;
    }

    h2 {
        border-bottom: 1px solid var(--color-light);
        padding-bottom: 0.25rem;
        margin-bottom: 0.5rem;
    }

    ul {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        max-height: 30vh;
        overflow: auto;
    }

    li {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--color-gray);
    }

    .links--children {
        display: flex;
        flex-direction: column;
    }

    a {
        padding: 1rem;
        width: 100%;
        color: var(--color-light);
        font-size: 24px;
        transition: 0.25s;
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
    }

    a:hover {
        text-decoration: underline var(--color-light);
    }

    .links--children > a {
        border-top: 1px solid var(--color-gray);
        font-size: 18px;
        padding: 1rem 2rem;
    }

    .router-link-exact-active {
        background-color: var(--color-yellow);
        color: var(--color-black);
        cursor: default;
    }

    .router-link-exact-active:hover {
        text-decoration: none;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: transform 0.3s ease-out;
    }

    .slide-enter-from,
    .slide-leave-to {
        transform: translateY(-100%);
    }

</style>