<template>

    <Transition name="slide-top" appear>
    <div id="cookies">

        <div class="cookies--container">
            
            <h2>Cookie Consent</h2>

            <hr>

            <p>This website uses cookies (disabled by default) for analytical purposes only. You can change your choice at any time in the web app settings.</p>

            <router-link to="/informations/politique-cookies">
                Cookies
            </router-link>

            <div class="cookies--controls">

                <button @click="saveChoice(false)" class="button__ignore">Decline</button>
                <button @click="saveChoice(true)" class="button__accept">Accept</button>
            
            </div>

        </div>

    </div>
    </Transition>
  
</template>

<script>

    export default {
        name: 'CookiesComponent',
        props: ['translations', 'config'],
        emits: ['saveConfig'],
        methods: {
            saveChoice (choice) {
                var config = this.config
                config.cookies = { 
                    consent: choice,
                    answeredOnce: true 
                }
                this.$emit('saveConfig', config)
            }
        }
    }

</script>

<style scoped>

    #cookies {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 1rem;
    }

    .cookies--container {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        border-radius: 0 0 4px 4px;
        background-color: var(--color-light);
        padding: 1rem;
        text-align: left;
    }

    hr {
        border-bottom: 1px solid var(--color-black);
        margin: 0.5rem 0;
    }

    p {
        margin-bottom: 0.5rem;
    }

    a {
        text-transform: uppercase;
        font-style: italic;
        transition: 0.25s;
    }

    a:hover {
        text-decoration: underline #0000EE;
    }

    .cookies--controls {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.5rem;
    }

    button {
        padding: 0.5rem;
        border-radius: 4px;
        cursor: pointer;
        text-transform: uppercase;
        transition: 0.25s;
    }

    button:hover {
        text-decoration: underline var(--color-black);
        filter: brightness(1.15);
    }

    .button__ignore {
        background-color: var(--color-red);
    }

    .button__accept {
        background-color: var(--color-green);
    }

    .slide-top-enter-active,
    .slide-top-leave-active {
        transition: all 0.5s ease;
    }

    .slide-top-enter-from,
    .slide-top-leave-to {
        opacity: 0;
        transform: translateY(-100%)
    }

</style>