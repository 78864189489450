<template>

    <footer>

        <div class="footer--container">
            <a href="https://twitter.com/hextechtools" target="_blank">
                <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="https://discord.gg/g3Mc2rP2Kr" target="_blank">
                <i class="fa-brands fa-discord"></i>
            </a>
        </div>

    </footer>
  
</template>

<script>

    export default {
        name: 'FooterComponent',
        props: ['translations', 'config']
    }

</script>

<style scoped>

    footer {
        margin-top: 1rem;
        max-height: 64px;
        height: 64px;
        padding: 0 1rem;
        background-color: var(--color-yellow);
        display: flex;
    }

    .footer--container {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    i {
        margin: 0 0.5rem;
        cursor: pointer;
        font-size: 26px;
        color: var(--color-black);
        opacity: 0.75;
        transition: 0.25s;
    }

    i:hover {
        opacity: 1;
    }

</style>